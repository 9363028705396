import React from "react";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function NewsItemShort({ title, date, content, size, news_id, cover_img }) {
  const width = size === "small" ? "100%" : "100%";
  const height = size === "small" ? "400px" : "100%";
  const navigate = useNavigate();

  return (
    <a href={`/news/${news_id}`} style={{ textDecoration: "none" }}>
      <Card
        style={{
          width: width,
          height: height,
          display: "flex",

          backgroundColor: "#c0c8cf",
          cursor: "pointer", // Indicates that the card is clickable
        }}
      >
        <Card.Title>
          <p
            style={{
              color: "#00ade6",
              fontSize: "25px",
              marginTop: "30px",
              marginLeft: "30px",
            }}
          >
            {date}
            <span>
              <img
                src={cover_img}
                alt="BatterySeminar"
                style={{ width: "30%", float: "right", marginLeft: "1rem" }}
              />
            </span>
          </p>
        </Card.Title>

        <Card.Body
          style={{
            textAlign: "left",
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingBottom: "30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              color: "#000045",
              fontSize: "26px",
              fontWeight: "bold",
              fontFamily: "Sans-serif",
            }}
          >
            {title}
          </p>
        </Card.Body>
      </Card>
    </a>
  );
}

export default NewsItemShort;
