import React from "react";
import { Container } from "react-bootstrap";
import cover_img9 from "../../assets/news_cover9.jpg";

function News8() {
  return (
    <Container>
      <br />
      <img
        src={cover_img9}
        style={{ width: "20%", float: "right", marginLeft: "1rem" }}
      />
      <p style={{ color: "grey", fontSize: "1.5rem", margin: "2rem" }}>
        February 20, 2025
      </p>
      <br />
      <h2
        style={{ color: "#003561", marginBottom: "1rem", marginLeft: "2rem" }}
      >
        Stratus Materials Inc. has been recognized as the Lithium-Ion Cathode
        Material of the Year by Energy Tech Review.{" "}
      </h2>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        Stratus Materials delivers innovative, manganese-rich, zero-cobalt
        cathode solutions that enhance lithium-ion battery performance, energy
        density, safety, and cost efficiency. Its proprietary LXMO materials
        overcome stability challenges, enabling global electrification and
        advancing sustainable energy storage for next-generation battery
        technologies.
      </p>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        “Due to low cost and versatility, our CAM materials perform across
        various durations and in different cell geometrics, which is highly
        suitable for stationary storage and even consumer electronics,” says{" "}
        <a
          href="https://www.linkedin.com/in/jay-whitacre-ph-d-1abb8134/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Jay Whitacre, Ph.D.
        </a>{" "}
        , CEO.
      </p>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        The article will also be featured in the forthcoming special print
        edition of Lithium-Ion Cathode Material 2025.{" "}
      </p>
      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        Read More from{" "}
        <a
          href="https://www.energytechreview.com/stratus-materials"
          target="_blank"
          rel="noopener noreferrer"
        >
          Energy Tech Review
        </a>{" "}
      </p>
      <a
        style={{
          fontSize: "1.5rem",
          margin: "2rem",
          textDecoration: "None",
          display: "flex",
          justifyContent: "center",
        }}
        href="/news"
      >
        <strong style={{ textAlign: "center" }}>BACK TO NEWS</strong>
      </a>
      <br />
      <br />
    </Container>
  );
}

export default News8;
