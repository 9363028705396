import React from "react";
import "./Careers.css";
import { Row, Container, Col, ListGroup } from "react-bootstrap";
import CareerHeaderImg from "../assets/career-header.jpg";
import PageHeader from "../components/PageHeader";
import DirVPofRD from "./Jobs/DirVPofRD-Stratus-Materials-2024-final.pdf";
import PowderTech from "./Jobs/Powder-Technician-Stratus-Materials-2025.pdf";
import SeniorProcessEngineer from "./Jobs/Process Development Engineer - Stratus Materials 2025.pdf";

const PageTitle = "Success Starts Here";
const SubTitle = "Careers at Stratus";
const SubContent =
  "Stratus Materials offers a collaborative, dynamic, and rewarding work environment for those that strive to make a difference and to further develop their skills and experience. We realize that the future success of our company is extremely dependent on our team and the outstanding people that we can attract. See our open positions below and if you feel you’re a good fit for team Stratus, apply!";

function Careers() {
  const jobOpenings = [
    { title: "Director / VP of Research & Development", pdf: DirVPofRD },
    { title: "Powder Production Technician", pdf: PowderTech },
    {
      title: "Senior Process Development Engineer",
      pdf: SeniorProcessEngineer,
    },
  ];

  return (
    <div>
      <PageHeader
        PageTitle={PageTitle}
        SubTitle={SubTitle}
        SubContent={SubContent}
        BackgroundImage={CareerHeaderImg}
      />

      <Container className="">
        <Row className="justify-content-left">
          <Col xs={12} md={10} lg={8}>
            <br />

            <h2
              className=" mb-4"
              style={{
                color: "#003561",
                fontFamily: "'Montserrat'",
                fontWeight: "800",
                marginBottom: "1.5rem", // Reduced margin for better spacing
              }}
            >
              Current Job Openings
            </h2>

            {jobOpenings.map((job, index) => (
              <a
                href={job.pdf}
                target="_blank"
                rel="noopener noreferrer"
                // style={{ textDecoration: "None" }}
              >
                <p
                  key={index}
                  // className={index % 2 === 0 ? "bg-light" : ""}
                  style={{
                    fontSize: "1.4rem", // Reduced font size for better readability on small screens
                    fontFamily: "Montserrat",
                    fontWeight: "400",
                  }}
                >
                  {job.title}
                </p>
              </a>
            ))}
          </Col>
        </Row>
        <p
          className=""
          style={{
            color: "#003561",
            fontSize: "1.1rem",
            fontFamily: "Montserrat",
            fontWeight: "400",
          }}
        >
          Please send your resume to{" "}
          <a href="mailto:careers@stratusmaterials.com">
            careers@stratusmaterials.com
          </a>{" "}
          and put the job title in the subject line.
        </p>
      </Container>

      <br />

      <br />
      <br />
    </div>
  );
}

export default Careers;
