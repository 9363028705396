import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import NewsItemShort from "./NewsItemShort";
import cover_img9 from "../assets/news_cover9.jpg";
import cover_img8 from "../assets/news_cover8.JPG";
import cover_img7 from "../assets/news_cover7.png";

const LatestNews = () => {
  const newsItems = [
    {
      title:
        "Stratus Materials has been recognized as the Lithium-Ion Cathode Material of the Year by Energy Tech Review.",
      date: "February 20, 2025",
      logo: cover_img9,
      news_id: 9,
    },
    {
      date: "December 20, 2024",
      logo: cover_img8,
      title:
        "U.S. DOE awards Stratus Materials $2.99 Million for Next-Generation Cathode Development",
      news_id: 8,
    },
    {
      date: "November 20, 2024",
      logo: cover_img7,
      title: "Stratus Materials to AABC, Las Vegas, Dec 9-12, 2024",
      news_id: 7,
    },
  ];

  return (
    <div style={{ backgroundColor: "#003561" }}>
      <Container
        style={{
          backgroundColor: "#003561",
          color: "white",
          padding: "20px 0",
        }}
      >
        <br />
        <h2
          style={{
            marginBottom: "20px",
            fontFamily: "Montserrat",
            fontWeight: "800",
          }}
        >
          Latest News
        </h2>
        <br />
        <div
          style={{
            height: "10px",
            width: "300px",
            backgroundColor: "#00ade6",
            marginBottom: "20px",
          }}
        ></div>
        <br />
        <Row>
          {newsItems.map((item, index) => (
            <Col key={index} md={4} className="mb-3">
              <div style={{ backgroundColor: "344D76" }}>
                <NewsItemShort
                  title={item.title}
                  date={item.date}
                  size="small"
                  news_id={item.news_id}
                  cover_img={item.logo}
                />
              </div>
            </Col>
          ))}
        </Row>

        <br />
        <br />
      </Container>
    </div>
  );
};

export default LatestNews;
