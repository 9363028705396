import React from "react";
import "./Home.css";
import LatestNews from "../components/LatestNews";
import { Row, Container, Col } from "react-bootstrap";
import home0Img from "../assets/home-0.png";
import HomeHeaderImg from "../assets/home-header.png";
import FeatureRow from "../components/FeatureRow";
import PageHeader from "../components/PageHeader";
import SeparationBar from "../components/SeparationBar";
import TextPic from "../components/TextPic";

const PageTitle = "Next-Gen Lithium-Ion Cathode Active Materials";
const SubTitle =
  "Stratus LXMO™ Cathode Active Materials (CAMs) offer a game-changing combination of performance, cost and safety.";
const SubContent = "";

const contentTitle = "Who We Are";
const contentText = `Stratus Materials is a U.S. based developer and
              manufacturer of manganese-rich, cobalt-free cathode active
              materials (CAMs) for lithium-ion batteries, offering a highly
              compelling combination of performance, safety, cost, and
              environmental impact to leading battery manufacturers and their
              OEM customers. Our LXMO cathode class is primarily focused on
              advanced solutions for light- to medium-duty electric vehicles,
              energy storage, and other applications with similar requirements.`;

function Home() {
  return (
    <div>
      <PageHeader
        PageTitle={PageTitle}
        SubTitle={SubTitle}
        SubContent={SubContent}
        BackgroundImage={HomeHeaderImg}
      />
      <br />
      <SeparationBar />
      <TextPic title={contentTitle} content={contentText} img={home0Img} height="550px" />

      <FeatureRow />
      <br />
      <LatestNews />

      <div style={{ margin: "60px" }}></div>
    </div>
  );
}

export default Home;
