import React from "react";
import "./News.css";
import { Row, Col } from "react-bootstrap";
import NewsItem from "../components/NewsItem";
import NewsItemWithCoverImg from "../components/NewsItemWithCoverImg";
import cover_img9 from "../assets/news_cover9.jpg";
import cover_img8 from "../assets/news_cover8.JPG";
import cover_img7 from "../assets/news_cover7.png";
import cover_img6 from "../assets/news_cover6.jpg";
import cover_img5 from "../assets/news_cover5.png"
import cover_img4 from "../assets/news_cover4.png"
import cover_img3 from "../assets/news_cover3.png"
import cover_img2 from "../assets/news_cover2.jpg"
import cover_img1 from "../assets/news_cover1.png"
const PageTitle = "News";

function News() {
  const newsItems = [
    {
      title:
        "Stratus Materials Inc. has been recognized as the Lithium-Ion Cathode Material of the Year by Energy Tech Review.",
      date: "February 20, 2025",
      news_id: 9,
      cover: cover_img9,
    },
    {
      title:
        "U.S. DOE awards Stratus Materials $2.99 Million for Next-Generation Cathode Development",
      date: "December 20, 2024",
      content:
        "Mark your calendars as Stratus Materials heads to the Advanced Automotive Battery Conference, Las Vegas on Dec 9 – 12, 2024.  On December 10th, Stratus’ CEO, Jay […]",
      news_id: 8,
      cover: cover_img8,
    },
    {
      title:
        "Stratus Materials to AABC, Las Vegas, Dec 9-12, 2024",
      date: "November 20, 2024",
      content:
        "Mark your calendars as Stratus Materials heads to the Advanced Automotive Battery Conference, Las Vegas on Dec 9 – 12, 2024.  On December 10th, Stratus’ CEO, Jay […]",
      news_id: 7,
      cover: cover_img7,
    },
    {
      title:
        "Stratus Materials Announces Achievement of Key Performance Milestone for its LXMO™ Li-ion Battery Cathode Active Material",
      date: "June 25, 2024",
      content:
        "PITTSBURGH, June 25, 2024 /PRNewswire/ — Stratus Materials Inc., a company focused on the development and commercialization of advanced cathode active materials (CAMs) for […]",
      news_id: 6,
      cover: cover_img6,
    },
    {
      title:
        "Stratus Materials Announces Updates for its Next-Generation LXMO™ Cathode Materials",
      date: "November 8, 2023",
      content:
        "PITTSBURGH, November 8, 2023 /BusinessWire/ — Stratus Materials Inc., a company focused on the development and commercialization of advanced cathode active materials (CAMs) for […]",
      news_id: 5,
      cover: cover_img5,
    },
    {
      title: "Stratus Materials to AABC, San Diego, Dec 11-14, 2023",
      date: "November 7, 2023",
      content:
        "Mark your calendars as Stratus Materials heads to Advanced Automotive Battery Conference, San Diego Dec 11 – 14th 2023. On December 12th, Stratus’ own CEO, Jay […]",
      news_id: 4,
      cover: cover_img4,
    },
    {
      title:
        "AABC Europe – June 19-22nd, Germany",
      date: "May 23, 2023",
      content:
        "The Stratus Materials team heads to Germany next month to attend the Advanced Automotive Battery Conference (AABC) Europe, a premier automotive battery event, bringing […]",
      news_id: 3,
      cover: cover_img3,
    },
    {
      title:
        "Stratus Materials Emerges from Stealth to Launch Next-Generation Cathode Materials",
      date: "February 7, 2023",
      content:
        "PITTSBURGH, February 7, 2023 /Business Wire — Stratus Materials Inc., a developer and manufacturer of next-generation cathode active materials for lithium-ion batteries, came out of stealth today.  The […]",
      news_id: 2,
      cover: cover_img2,
    },
    {
      title: "Stratus Materials Heads to International Battery Seminar",
      date: "February 7, 2022",
      content:
        "March 20-23, 2023 Orlando, Florida The Stratus Materials team heads to The International Battery Seminar 1,500 battery technologists from key OEMs, leading cell manufacturers, […]",
      news_id: 1,
      cover: cover_img1,
    },
    // Add more news items here as needed
  ];

  return (
    <div>
      <header
        className=" d-flex align-items-center text-light shadow"
        style={{
          height: "20vh",
          backgroundColor: "#003561"
        }}
      >
        <div className="container">
          <Row className="mt-5">
            <Col sm={12} md={8} lg={6}>
              <h1
                className="text-white fw-bold lh-sm"
                style={{
                  fontSize: "3rem", // Reduced font size for better readability on small screens
                  fontFamily: "Montserrat",
                }}
              >
                {PageTitle}
              </h1>
            </Col>
          </Row>
        </div>
      </header>

      <div className="container" >
        <br />
        {newsItems && newsItems.map((item) => (
          <div key={item.news_id}>
            <NewsItemWithCoverImg
              title={item.title}
              date={item.date}
              content={item.content}
              news_id={item.news_id}
              coverImage={item.cover}
            />
            <br />
          </div>
        ))}
      </div>

      <br />
    </div>
  );
}

export default News;
